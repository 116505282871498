import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container mx-auto lg:w-11/12 mt-12"
}
const _hoisted_2 = { class: "flex flex-row justify-between items-center bg-white rounded-md border border-gray-200" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row items-center p-3"
}
const _hoisted_4 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_5 = {
  key: 1,
  class: "flex flex-row items-center text-sm font-medium mr-4 space-x-5"
}
const _hoisted_6 = { class: "flex my-6 space-x-8" }
const _hoisted_7 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_SidebarNavigationLink = _resolveComponent("SidebarNavigationLink")!
  const _component_SidebarNavigationGroup = _resolveComponent("SidebarNavigationGroup")!
  const _component_SidebarNavigation = _resolveComponent("SidebarNavigation")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _directive_feature_flagged = _resolveDirective("feature-flagged")!

  return (_openBlock(), _createElementBlock("section", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.currentOrganisation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_ButtonLink, {
                    class: "mr-4",
                    size: "small",
                    color: "ghost",
                    to: "/admin/organisations"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BaseIcon, {
                        name: "arrow-left",
                        class: "stroke-1.5"
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.currentOrganisation.name), 1),
                  _createVNode(_component_MkBadge, {
                    color: _ctx.currentOrganisation.type === 'organisations' ? 'primary' : 'success',
                    class: "ml-4 capitalize",
                    stroked: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`platform.domain-admin.${_ctx.org.type}.name`)), 1)
                    ]),
                    _: 1
                  }, 8, ["color"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.parentTree && _ctx.parentTree.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentTree, (parent, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: parent,
                      class: "inline-flex space-x-5 items-center"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(parent), 1),
                      (index < _ctx.parentTree.length - 1)
                        ? (_openBlock(), _createBlock(_component_BaseIcon, {
                            key: 0,
                            name: "right-vector-arrow",
                            height: "56px"
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_SidebarNavigation, null, {
              default: _withCtx(() => [
                _createVNode(_component_SidebarNavigationGroup, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SidebarNavigationLink, {
                      to: `/admin/organisations/${_ctx.organisationId}/details`,
                      "data-dusk": "side-nav-settings",
                      icon: "cog"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.settings')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    _createVNode(_component_SidebarNavigationLink, {
                      to: `/admin/organisations/${_ctx.organisationId}/users`,
                      "data-dusk": "side-nav-users",
                      icon: "users-multiple"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.users')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (!_ctx.isDomainOrSubdomain)
                      ? (_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                          key: 0,
                          to: `/admin/organisations/${_ctx.organisationId}/roles`,
                          icon: "circuit"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.roles-permissions')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (!_ctx.isDomainOrSubdomain)
                      ? (_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                          key: 1,
                          to: `/admin/organisations/${_ctx.organisationId}/devices`,
                          icon: "workstation"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.devices')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (!_ctx.isDomainOrSubdomain)
                      ? (_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                          key: 2,
                          to: `/admin/organisations/${_ctx.organisationId}/settings`,
                          icon: "building"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.processing')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _withDirectives((_openBlock(), _createBlock(_component_SidebarNavigationLink, {
                      to: `/admin/organisations/${_ctx.organisationId}/preferences`,
                      icon: "preferences1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.preferences')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])), [
                      [_directive_feature_flagged, _ctx.preferencesFeature]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.loading)
                ? (_openBlock(), _createBlock(_component_RouterView, {
                    key: 0,
                    organisation: _ctx.currentOrganisation,
                    onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateOrganisation($event)))
                  }, null, 8, ["organisation"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}