
import { Vue, Options } from 'vue-class-component';
import axios, { CancelTokenSource } from 'axios';
import { SidebarNavigationLink, SidebarNavigationGroup, SidebarNavigation, BaseIcon } from '@/lib/components';
import { Domain, Organisation } from '@/models';
import { DomainService, OrganisationService } from '@/services/api';
import { getParentTree, searchTree } from '@/helpers/organisations.helper';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import { FEATURES } from '@/constants';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    ButtonLink,
    BaseIcon,
    SidebarNavigationGroup,
    SidebarNavigationLink,
    SidebarNavigation
  }
})
export default class OrganisationLayout extends Vue {
  organisationId!: string;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  organisation: Organisation | null = null;
  orgService = new OrganisationService();
  domainService = new DomainService();
  request: CancelTokenSource | null = null;
  loading = false;
  preferencesFeature = FEATURES.UNIT_PREFERENCES;
  domains: Domain[] = [];

  get parentTree(): Array<string> | undefined {
    return this.domains
      .map((domain) => getParentTree(this.organisationId, domain).map((o) => o.name))
      .find((arr) => arr.length);
  }

  get isDomainOrSubdomain(): boolean {
    return this.currentOrganisation?.type === 'domain' || this.currentOrganisation?.type === 'subdomain';
  }

  get org() {
    return this.domains.map((d) => searchTree(d, this.organisationId)).find((org) => org);
  }

  get currentOrganisation() {
    return this.organisation || this.org;
  }

  async created() {
    try {
      this.loading = true;
      this.progressStore.startProgress();
      await this.fetchDomains();
      if (!this.org || this.org.type === 'organisations') {
        await this.fetchOrganisation();
      }
      this.progressStore.finishProgress();
    } catch (e) {
      this.progressStore.setError();
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    } finally {
      this.loading = false;
    }
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchDomains() {
    this.domains = (await this.domainService.index()).data;
  }

  async fetchOrganisation() {
    this.request = axios.CancelToken.source();
    try {
      this.organisation = await this.orgService.fetch(this.organisationId, {
        cancelToken: this.request.token
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.org-unit.fetch-one-error')
        });
      }
    } finally {
      this.request = null;
    }
  }

  async updateOrganisation(organisation: Domain | Organisation) {
    if (organisation.type === 'domain' || organisation.type === 'subdomain') {
      await this.fetchDomains();
    } else {
      this.organisation = organisation as Organisation;
      await this.sessionStore.fetchCurrentUser();
    }
  }
}
